export const FirebaseOptions = {
  apiKey: "AIzaSyBbYVCMVYlm9IpR2Cg7uwSujkIpMtTIXh8",
  authDomain: "lesjours.firebaseapp.com",
  projectId: "lesjours",
  storageBucket: "lesjours.appspot.com",
  messagingSenderId: "582076808699",
  appId: "1:582076808699:web:d181eb4590876c38459f5e",
  measurementId: "G-ZE47TY25GD",
};

export const WebPushCertificates = {
  vapidKey: "BJsdIOBLIbJ9rxMryTykpIE46KsmMZkR16IW6KOHkkMwq9tGpOMSSFOO6r2yyuMSvmPEvo5roepzfsN-_ACjxdc",
};

export const SignInWithOptions = {
  appleClientId: "fr.lesjours.website",
  googleClientId: "582076808699-p9pi4leidfshri4ucsv95hikj7s3ag4g.apps.googleusercontent.com",
  redirectURI: "https://lesjours.fr/mon-compte/register",
};

export const SubscribeWithGoogleOptions = {
  productId: "lesjours.fr:jouriste",
  ljOAuthClientId: "google-swg",
};
