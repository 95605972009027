/* global clients:true */
// These JavaScript module imports need to be bundled:
import { clientsClaim } from "workbox-core";
import { cleanupOutdatedCaches, precacheAndRoute } from "workbox-precaching";
// Import Mozilla localForage Library
import localForage from "localforage";

// Note: Ignore the error that Glitch raises about workbox being undefined.
self.skipWaiting();
clientsClaim();

// Configure the Storage
localForage.config({
  name: "LesJours Service Worker",
  version: 1.0,
  storeName: "lesjours-sw",
});

// Add Precache Routes
precacheAndRoute(self.__WB_MANIFEST);

// Cleanup Workbox Old Caches
cleanupOutdatedCaches();

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTIFICATION CLICK HANDLER                                                                                         //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * Note: If you want to define customized behavior in the service worker when the notification is clicked, make sure to handle notificationclick before you import FCM functions or libraries.
 * Otherwise, FCM may overwrite the custom behavior.
 * https://firebase.google.com/docs/cloud-messaging/js/receive?hl=en
 */
// Listen Clicks Events
self.addEventListener("notificationclick", function (event) {
  // Get the Notification Payload & the Service Worker
  const serviceWorker = event.currentTarget;
  const message = event.notification.data.FCM_MSG;
  const payload = message.data;
  console.log("[sw.js] Clicked notification ", message);

  // Close the Notification
  event.notification.close();

  // Open the URL
  clients.openWindow(
    serviceWorker.location.origin +
      payload.url +
      "?mtm_source=notification&mtm_medium=ServiceWorker&mtm_campaign=notification"
  );
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FIREBASE MESSAGING                                                                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Give the service worker access to Firebase Messaging.
// Note that you can only use Firebase Messaging here, other Firebase libraries
// are not available in the service worker.
import { initializeApp } from "firebase/app";
import { getMessaging, isSupported, onBackgroundMessage } from "firebase/messaging/sw";
import { FirebaseOptions } from "../js/config";

// Initialize the Firebase app in the service worker by passing in the messagingSenderId.
const firebaseApp = initializeApp(FirebaseOptions);

// Check if Firebase Messaging is Supported
isSupported().then((supported) => {
  // Nothing to do if the browser doesn't support notifications
  if (!supported) {
    return;
  }

  // Retrieve an instance of Firebase Messaging so that it can handle background messages.
  const firebaseMessaging = getMessaging(firebaseApp);

  // Listen Push Events
  onBackgroundMessage(firebaseMessaging, (payload) => {
    const data = payload.data;
    console.log("[sw.js] Received background message ", data);

    // Nothing to do yet
    // The internal code of FCM SDK has already taken care about displaying the notification
  });
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NIGHT MODE                                                                                                         //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Listen Message from the Window
self.addEventListener("message", function (event) {
  // Check the Event Action
  switch (event.data.action) {
    case "getTheme":
      // Get the Theme
      localForage.getItem("theme").then(function (theme) {
        // Send the Response
        event.ports[0].postMessage({ theme: theme === "dark" ? "dark" : "light" });
      });
      break;
    case "setTheme":
      // Update the Theme
      localForage.setItem("theme", event.data.theme).then(function () {
        // Send the Response
        event.ports[0].postMessage({});
      });
      break;
    default:
      break;
  }
});
